.alert-danger {
    color: #f1416c !important;
    border-color: #f1416c !important;
    background-color: #fff5f8 !important;
}

.alert-danger {
    --bs-alert-color: #601a2b !important;
    --bs-alert-bg: #fcd9e2 !important;
    --bs-alert-border-color: #f9b3c4 !important;
    --bs-alert-link-color: #601a2b !important;
}

.text-danger {
    color: #f1416c !important;
}

.p-5 {
    padding: 1.25rem !important;
}

.mb-19 {
    margin-bottom: -1.2rem;
}

.align-items-center {
    align-items: center !important;
}

.d-flex {
    display: flex !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.fs-2hx {
    font-size: 2.5rem !important;
}

.me-4 {
    margin-right: 1rem !important;
}

h4 {
    font-size: 1.25rem !important;
}

@media only screen and (max-width: 767px) {
    .mobileviewalert {
        margin-right: 15px;
        margin-left: 15px;
    }

    .p-5 {
        padding: 15px 8px 15px 32px !important;
    }
}

@media only screen and (max-width: 767px) {
    .mobileviewalert {
        margin-right: 15px;
        margin-left: 15px;
    }

    .p-5 {
        padding: 15px 8px 15px 32px !important;
    }
}
 /*language translator*/

 .lang-translator {
    align-self: center;
}


.lang-char {
    padding: 0x;
    font-weight: 500;
}

    .lang-char :hover {
        color: #385aeb;
    }

.lang-seperator {
    padding: 0px;
    cursor: default;
}

.lang-globe {
    font-size: 1.35em;
    color: #b5b5c3;
    cursor: default;
}

.lang-underline {
    text-decoration: underline;
}

/*end language translator*/
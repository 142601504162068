
    .spinftw {
        border-radius: 100%;
        display: inline-block;
        height: 30px;
        width: 30px;
        top: 50%;
        position: absolute;
        -webkit-animation: loader infinite 2s;
        -moz-animation: loader infinite 2s;
        animation: loader infinite 2s;
        box-shadow: -20px -12px #003399, 20px -9px #009966, -2px 21px #FF3333;
        background-size: contain;
    }
    @-webkit-keyframes loader {
        0%, 100% {
            box-shadow: -20px -12px #003399, 20px -9px #009966, -2px 21px #FF3333;
        }

        33% {
            box-shadow: 20px -9px #003399, -2px 21px #009966, -20px -12px #FF3333;
        }

        66% {
            box-shadow: -2px 21px #003399, -20px -12px #009966, 20px -9px #FF3333;
        }
    }

    @-moz-keyframes loader {
        0%, 100% {
            box-shadow: -20px -12px #003399, 20px -9px #009966, -2px 21px #FF3333;
        }

        33% {
            box-shadow: 20px -9px #003399, -2px 21px #009966, -20px -12px #FF3333;
        }

        66% {
            box-shadow: -2px 21px #003399, -20px -12px #009966, 20px -9px #FF3333;
        }
    }

    @keyframes loader {
        0%, 100% {
            box-shadow: -20px -12px #003399, 20px -9px #009966, -2px 21px #FF3333;
        }

        33% {
            box-shadow: 20px -9px #003399, -2px 21px #009966, -20px -12px #FF3333;
        }

        66% {
            box-shadow: -2px 21px #003399, -20px -12px #009966, 20px -9px #FF3333;
        }
    }

    .exactCenter {
        width: 300px;
        height: 200px;
        position: fixed;
        top: 49%;
        left: 50%;
        background-color: white;
        margin-top: -100px;
        margin-left: -100px;
        z-index: 9999999 !important;
    }


    #CssLoader {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #CssLoader {
        text-align: center;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(255,255,255, 0.9);
        z-index: 9999999 !important;
    }

    .spinftw {
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
    }
